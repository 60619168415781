
import Vue from 'vue';
import MapComponent from '@/shared/legacy/map/map.vue';
import { UnsafeAny } from '@/shared/types';
import { Services } from '@/injectables/tokens';
import { LoggerContract } from '@/injectables';

export default Vue.extend({
  name: 'SharedOttMap',

  useInjectable: [Services.Polygons],

  components: { MapComponent },

  props: {
    geoSelections: {
      type: Object,
      default: () => ({}),
    },
  },

  data: (): {
    showMap: boolean;
    mapHeight: number;
    loadedPolygons: UnsafeAny;
    mapId: string;
  } => ({
    mapId: 'sharedOttMap',
    showMap: false,
    mapHeight: 800,
    loadedPolygons: null,
  }),

  computed: {
    selectedMap(): string {
      return this.$store.state.map.defaultMap;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.showMap = true; // let the container render first, to get its height
      this.mapHeight = this.$refs.mapContainer?.offsetHeight || 900;
    });
  },

  methods: {
    async onMapReady(): Promise<void> {
      const map = this.$refs[this.mapId]?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }
      if (Object.values(this.geoSelections).some((arr: UnsafeAny[]) => arr.length)) {
        const { isErr, unwrap, unwrapErr } = await this.polygonsService.getPolygons({
          states: this.geoSelections?.stateList,
          dmas: this.geoSelections?.dmaList,
          counties: this.geoSelections?.countyList,
          cities: this.geoSelections?.cityList,
          zips: this.geoSelections?.zipList,
        });

        if (isErr()) {
          const { message = '' } = unwrapErr();
          const logger: LoggerContract = this.$container.get('logger');
          logger.print('error', 'sharedOttMap', message);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        const { pstate, ...polygons } = unwrap();

        this.loadedPolygons = polygons;

        this.renderPolygons();
      } else {
        map.ClearMap();
        map.SetView([40, -100], 4);
      }
    },
    renderPolygons(): void {
      const map = this.$refs[this.mapId]?.Get();

      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      map.RemoveLayer('allPolygons');
      const polyLayer = map.CreateLayer('allPolygons');

      let hasPolygons = false;

      Object.keys(this.loadedPolygons).forEach((key: string) => {
        if (key !== 'LocationNotFound') {
          const polygon = this.loadedPolygons[key];

          const layer = map.AddToLayer(polyLayer);

          map.SetWKT(layer, polygon.WKT_P100, true);

          hasPolygons = true;

          const polyStyle = {
            fillOpacity: 0.4,
            color: this.$vuetify.theme.themes.light.primary,
            fillColor: this.$vuetify.theme.themes.light.primary,
            weight: 3,
          };

          layer.setStyle(polyStyle);
        }
      });

      if (hasPolygons) {
        const paddingOptions = { paddingTopLeft: [10, 10], paddingBottomRight: [360, 10] };
        const mapEl = this.$refs[this.mapId]?.$el;

        const replaceBackground = (retries = 5) => {
          setTimeout(() => {
            if (!mapEl || !map) {
              // eslint-disable-next-line no-console
              console.error('Shared-Ott-Map can not export, no map element', this.mapCacheKey, { map, mapEl });
              if (retries > 0) {
                setTimeout(() => {
                  replaceBackground(retries - 1);
                }, 500);
              } else {
                setTimeout(() => {
                  this.$emit('rendered', { empty: true });
                }, 10);
              }
              return;
            }
            map.FitAllLayers({ force: true, animate: false, ...paddingOptions });

            const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
            const nestedCanvasList = mapEl.getElementsByClassName('mapboxgl-canvas-container');

            if (!tileBackgroundList || tileBackgroundList.length === 0) {
              // eslint-disable-next-line no-console
              console.error('Shared-Ott-Map: can not export, no map background');
              if (retries > 0) {
                setTimeout(() => {
                  replaceBackground(retries - 1);
                }, 500);
              } else {
                setTimeout(() => {
                  this.$emit('rendered', { empty: true });
                }, 10);
              }
              return;
            }

            const tileBackground = tileBackgroundList?.[0] as HTMLElement;
            const nestedCanvas = nestedCanvasList?.[0] as HTMLElement;
            const zoom = Math.round(map.GetZoom()) - 1;

            const center = map.GetCenter();

            const staticMapByCenter = `https://api.maptiler.com/maps/${this.selectedMap}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;

            const img = new Image();
            img.addEventListener(
              'load',
              () => {
                Object.assign(tileBackground.style, {
                  backgroundImage: 'url(' + staticMapByCenter + ')',
                  transform: 'none',
                });
                nestedCanvas.style.display = 'none';
                setTimeout(() => {
                  this.$emit('rendered', { empty: false });
                }, 500);
              },
              false,
            );
            img.src = staticMapByCenter;
          }, 500);
        };

        replaceBackground();
      }
    },
  },
});
