
import Vue from 'vue';

import SharedOttMap from '@/components/SharedOtt/SharedOttMap.vue';
import TargetedDemographics from '@/components/Proposal/TargetedDemographics.vue';
import { ProposalDemographic, UnsafeAny } from '@/shared/types';
import { Models } from '@/injectables/tokens';
import {
  BaseProductModelContract,
  NewProposalModelContract,
  ProductModelContract,
  TAvailsProduct,
} from '@/injectables';
import { ProductConfigCategory } from '@/app/graphql';
import ProductInfo from '@/components/ProductElement/ProductInfo.vue';

export default Vue.extend({
  name: 'sharedOtt',

  components: {
    SharedOttMap,
    TargetedDemographics,
    ProductInfo,
  },

  useInjectable: [Models.NewProposal, Models.Product, Models.BaseProduct],

  data: (): {
    demographicsList: ProposalDemographic[];
    totalPopulation: number;
    dates: string[];
    product: TAvailsProduct;
    avails: number;
    exportingPageHeight: number;
    geoSelections: { [polygonLayer: string]: string[] };
    mapRendered: boolean;
  } => ({
    geoSelections: {},
    exportingPageHeight: 960,
    avails: 0,
    dates: [],
    product: null,
    demographicsList: [],
    totalPopulation: 0,
    mapRendered: false,
  }),

  mounted(): void {
    const { list } = (this.newProposalEntity as NewProposalModelContract).demographicSelections();
    this.demographicsList = list;

    const parseJWT = token => {
      try {
        const [, payload = ''] = token.split('.');
        if (!payload) return null;
        return JSON.parse(atob(payload));
      } catch {
        return null;
      }
    };

    if (this.$route.params?.token) {
      const token = this.$route.params.token;
      const parsedToken = parseJWT(token);
      if (parsedToken) {
        const {
          startDate,
          endDate,
          geoSelections = {},
          demographics = {},
          totalAvails = 0,
          productName = '',
        } = parsedToken;
        const { list = [], totalPopulation = 0 } = demographics;
        const product = this.availableProducts.find(pr => pr.name === productName);

        this.dates = [startDate, endDate];
        if (product) this.product = product;
        this.geoSelections = geoSelections;
        this.demographicsList = list;
        this.totalPopulation = totalPopulation;
        this.avails = totalAvails;
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('missing parameter in url');
      this.$store.dispatch('showSnackbar', { content: 'Sorry, an error occurred', color: 'error' });
      return;
    }
  },

  computed: {
    availableProducts() {
      return (this[Models.Product] as ProductModelContract).availsAvailableProducts();
    },
  },

  methods: {
    productIcon(categoryName: ProductConfigCategory): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(categoryName).icon;
    },
  },
});
